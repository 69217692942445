import { useEffect } from 'react';
import widgetsDb from '@/services/firestore/widgets';
import * as Sentry from '@sentry/nextjs';
const PREFIX = 'common: hooks: useReportingWidget:'; // eslint-disable-next-line

export default function useReportingWidget(db, widget) {
  var _widget$reportingWidg;

  const hasReportingWidget = Boolean(widget && widget.reportingWidget && widget.reportingWidget.id); // Lookup a widgets' reporting widget

  const reportingWidgetId = (widget === null || widget === void 0 ? void 0 : (_widget$reportingWidg = widget.reportingWidget) === null || _widget$reportingWidg === void 0 ? void 0 : _widget$reportingWidg.id) || 'na-widget-id';
  let result;
  let resultError = undefined; // Report any failures to sentry

  useEffect(() => {
    if (widget && reportingWidgetId && !resultError) return;
    Sentry.captureException(Error(`${PREFIX}: ${resultError}`)); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultError]);

  try {
    result = widgetsDb.findRecord(db, reportingWidgetId);
    resultError = result.error || null;
  } catch (err) {// NOTE: Discarding weird react queue errors
  } // Wait for source widget to load


  if (!widget) {
    return {
      status: 'loading',
      error: null,
      data: null
    };
  } else if (widget && !hasReportingWidget) {
    // Return source widget as reporting widget
    // when the active widget is not a subscriber
    return {
      status: 'success',
      error: null,
      data: widget
    };
  } // Load reporting widget reporting widget
  // @ts-ignore


  return result;
}